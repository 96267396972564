export  const recordTypelist = {

    '1':'蓝牙开锁',
    '4':'密码开锁成功',
    '5':'在锁上修改密码',
    '6':'在锁上删除一个密码',
    '7':'密码开锁失败，未知密码',
    '8':'清空密码',
    '9':'密码被挤掉',
    '10':'带删除功能密码第一次开锁，之前密码被清空',
    '11':'密码开锁失败，密码已过期或未生效',
    '12':'密码开锁失败，存储容量不足',
    '13':'密码开锁失败一密码在黑名单',
    '14':'门锁重新上电启动了',
    '15':'添加IC卡成功',
    '16':'清空IC卡',
    '17':'IC卡开门成功',
    '18':'删除单个IC卡',
    '19':'Bong手环开门成功',
    '20':'指纹开锁成功',
    '21':'指纹添加成功',
    '22':'指纹开门失败,已过期或者未生效',
    '23':'删除单个指纹',
    '24':'清空指纹',
    '25':'IC卡开门失败,已过期或者未生效',
    '26':'蓝牙闭锁',
    '27':'机械钥匙开锁',
    '28':'网关开锁',
    '29':'非法开锁(比如车位锁被强制打开)',
    '30':'门磁合上(关门)',
    '31':'门磁打开(开门)',
    '32':'从内部打开门',
    '33':'指纹关锁',
    '34':'密码关锁',
    '35':'IC卡关锁',
    '36':'机械钥匙关锁',
    '37':'遥控器按键',
    '38':'密码开锁失败，门反锁了',
    '39':'IC卡开锁失败，门锁反锁了',
    '40':'指纹开锁失败，门反锁了',
    '41':'App开锁失败，门反锁了',
    '42':'邮局本地邮件',
    '43':'邮局外地邮件',
    '44':'防撬报警',
    '45':'超时自动闭锁(比如时间段常开模式下超时闭锁，超声波传感器超时自动闭锁)',
    '46':'开锁按键开锁',
    '47':'闭锁按键闭锁',
    '48':'系统被锁定(如输入密码连续错误5次)',
    '49':'酒店卡开锁',
    '50':'高温开锁',
    '51':'IC卡开锁失败，黑名单卡',
    '52':'用APP锁定锁',
    '53':'用密码锁定锁',
    '54':'车离开(车位锁才有)',
    '55':'无线钥匙按键',
    '56':'无线键盘电量',
    '57':'二维码开锁成功',
    '58':'二维码开锁失败，过期',
    '59':'开启反锁',
    '60':'关闭反锁',
    '61':'二维码闭锁成功',
    '62':'二维码开锁失败，门已反锁',
    '63':'常开时间段自动开锁',
    '64':'门未关报警',
    '65':'开锁超时',
    '66':'闭锁超时',
    '67':'3D人脸开锁成功',
    '68':'3D人脸开锁失败-门反锁了',
    '69':'3D人脸闭锁',
    '70':'注册3D人脸成功',
    '71':'3D人脸开门失败一已过期或者未生效',
    '72':'删除人脸成功',
    '73':'清空人脸成功',
    '74':'IC卡开锁失败-CPU安全信息错',
    '75':'App授权按键开锁成功',
    '76':'网关授权按键开锁成功',
    '77':'双重认证蓝牙开锁验证成功，等待第二用户',
    '78':'双重认证密码开锁验证成功，等待第二用户',
    '79':'双重认证指纹开锁验证成功，等待第二用户',
    '80':'双重认证IC卡开锁验证成功，等待第二用户',
    '81':'双重认证人脸卡开锁验证成功，等待第二用户',
    '82':'双重认证无线钥匙开锁验证成功，等待第二用户',
    '83':'双重认证掌静脉开锁验证成功，等待第二用户',
    '84':'掌静脉开锁成功',
    '85':'掌静脉开锁失败-门反锁了',
    '86':'掌静脉闭锁',
    '88':'掌静脉开门失败一已过期或者未生效',
}   